$c-blue: #1188e6;

$c-jiva: #d01012;

$c-karma: #222222;

$c-period: #14a651;

$c-yearnum: #dbae1d;

:root {
  font-family: Arial, sans-serif;
  font-size: 12px;
}

#main-numbers {
  list-style: none;
  padding: 10px 0;

  li {
    display: inline-block;
    padding-right: 4ex;
  }
}

.yantra {
  display: flex;

  flex-wrap: wrap;

  max-width: 200px;

  &__cell {
    width: 30%;
    position: relative;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &__1 {
      order: 2
    }

    &__2 {
      order: 7
    }

    &__3 {
      order: 1
    }

    &__4 {
      order: 9
    }

    &__5 {
      order: 6
    }

    &__6 {
      order: 4
    }

    &__7 {
      order: 5
    }

    &__8 {
      order: 8
    }

    &__9 {
      order: 3
    }

    &_inner {
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;

      padding: 2px;
      background-color: #f0f0f0;

      display: flex;

      justify-content: center;
      align-items: center;

      flex-wrap: wrap;
    }
  }

  &__number {
    $size: 2ex;
    display: inline-block;
    padding: 2px;
    margin: 2px;
    height: $size;
    width: $size;
    line-height: $size;
    vertical-align: middle;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 50%;

    &.type-jiva {
      background-color: $c-jiva;
      color: white;
    }

    &.type-karma {
      background-color: $c-karma;
      color: white;
    }

    &.type-period {
      background-color: $c-period;
      color: white;
    }

    &.type-yearnum {
      background-color: $c-yearnum;
      color: white;
    }
  }
}

.periods-list {
  display: flex;
  flex-wrap: nowrap;

  overflow-x: scroll;

  padding-bottom: 3px;

  margin-left: -30px;
  margin-right: -30px;

  .period {
    flex-basis: 200px;
    flex-shrink: 0;
    padding-left: 30px;
  }
}

#calculate {
  background-color: $c-blue;
  padding: 3px 5px;
  margin: 3px;
  color: white;
  cursor: pointer;
  transition: background-color 300ms;

  &:hover {
    background-color: darken($c-blue, 10%);
  }
}

body {
  margin: 0;
}

#root {
  padding: 15px;
  position: relative;
  overflow: hidden;

  &.progress {

    &:after {
      content: "";
      display: block;
      position: absolute;
      background-color: rgba(#ffffff, 0.8);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
